<template>
    <div class="body-container">
        <BannerSection title="Préstamos en línea al instante<br class='d-none d-lg-block'/> con mi auto"
                       subtitle="Obtén dinero rápido con tu carro como aval" />
        <PerksComponent></PerksComponent>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p>¿Necesitas dinero urgentemente y no quieres complicarte? Con nuestros préstamos online, utilizas
                        tu auto como garantía y sigues disfrutándolo sin interrupciones. </p>
                    <br>
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">Tu préstamo en un clic: ¡Descubre cómo!</h3>
                    </span>
                    <p>Obtener tu préstamo en línea al instante es más fácil de lo que imaginas. Así puedes conseguir
                        dinero rápido usando tu auto como aval.</p>
                    <ol>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Completa el formulario en línea.
                            </p>
                            <span> Solo te tomará unos minutos. Ingresa los datos básicos de tu auto y tu información
                                personal.</span>
                        </li>

                        <br>
                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Recibe una oferta instantánea.
                            </p>
                            <span> No tendrás que esperar; evaluamos tu solicitud al momento y te enviamos una propuesta
                                que se ajusta a tus necesidades.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Envía la documentación necesaria.
                            </p>
                            <span>Con solo unos clics, sube los documentos requeridos directamente desde tu móvil o
                                computadora.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text"
                               style="font-size: 20px;">
                                Disfruta de tu dinero en menos de 48 horas.
                            </p>
                            <span>Si todo está en orden, transferimos el dinero directamente a tu cuenta bancaria
                                rápidamente.</span>
                        </li>

                        <br>
                    </ol>
                    <p>Con nuestros préstamos en línea por tu auto, valoramos tu tiempo y nos aseguramos de que obtengas
                        lo que necesitas sin demoras. </p>
                    <br>
                </div>
            </div>
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <h3 style="font-size: 20px;">¿Por qué elegir nuestro servicio?
                        </h3>
                       
                    </span>
                    <p>Cuando necesitas dinero urgente, lo último que quieres son procesos largos y complicados. Te
                        contamos por qué somos tu mejor opción para obtener un préstamo por tu auto en línea:</p>
                    <ul>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Instantaneidad
                            </h4>
                            <span> ¿Imaginas tener dinero disponible en solo 48 horas? Con nosotros, es posible.
                                Trabajamos rápido para que no tengas que esperar.</span>
                        </li>

                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Facilidad
                            </h4>
                            <span>Todo el proceso se maneja online. Desde tu casa o donde estés, sólo necesitas conexión
                                a internet para solicitar tu préstamo.</span>
                        </li>
                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Flexibilidad
                            </h4>
                            <span>¿Te preocupa tu mal historial en el Buró de Crédito? Para nosotros, eso no es un
                                obstáculo. Evaluamos cada caso con cuidado y ofrecemos soluciones a tu medida.</span>
                        </li>
                        <br>
                        <li>
                            <h4 class="mb-0 bold-text"
                                style="font-size: 20px;">
                                Sin perder tu auto.
                            </h4>
                            <span> Úsalo como garantía sin tener que dejar de conducirlo. Así de simple. Tu vida sigue
                                igual, pero con el dinero que necesitas.</span>
                        </li>
                        <br>
                    </ul>
                    <h3 class="mb-0 bold-text"
                        style="font-size: 20px;">Con Ibancar, obtener el dinero que necesitas es fácil y seguro </h3>
                    
                </div>
            </div>
        </div>

        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h3 style="font-size: 20px;">Préstamo a tu medida</h3>
                       
                    </span>
                    <p>Cuando decides confiar en nosotros para tu préstamo, te garantizamos claridad y facilidad en cada
                        paso. </p>
                    <ul>
                        <li>
                            <span><b>Monto máximo disponible:</b> ¿Cuánto necesitas? Puedes obtener hasta $120.000 MXN
                                con nosotros, dependiendo del valor de tu auto y tu situación financiera.</span>
                        </li>

                        <br>
                        <li>
                            <span><b>Términos flexibles y claros. </b>Sabemos que cada persona tiene necesidades
                                diferentes. Por eso, ofrecemos plazos de pago que se ajustan a tu capacidad económica.
                                Todo explicado sin letras pequeñas.</span>
                        </li>
                        <br>

                    </ul>
                    <p>Con nosotros, tienes la tranquilidad de saber que todo está diseñado para tu conveniencia.</p>
                    <br>

                    <h3 class="bold-text"
                        style="font-size: 20px;">¡No esperes más! Empieza tu solicitud hoy mismo </h3>
                   
                    <h4 style="font-size: 20px;">Resuelve tus necesidades financieras de manera rápida y sin
                        complicaciones. Es simple, rápido, y lo gestionas todo en línea desde la comodidad de tu hogar.
                    </h4>

                    
                </div>
            </div>
        </div>

        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamosEnLineaAlInstante',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamos en línea al instante con mi auto | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Obtén préstamos en línea al instante con tu auto en garantía. Sin trámites complicados, con aprobación inmediata y seguridad garantizada. ¡Pide el tuyo ya!'
                    },
                    {
                        name: 'keywords',
                        content: 'prestamos en linea al instante prestamos por mi auto en linea'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/prestamos-sin-buro', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.image-container {
    text-align: center;
    margin: 3rem 0rem;

    img {
        max-height: 400px;
    }
}

.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>